import React, { useEffect } from "react";
import { useUpdateFormContext } from "../../../context/administration/updateForm";
import { useLocation } from "react-router-dom";

const UpdateFormTabs = ({ caseId }) => {
  const { activeTab, setActiveTab, formData } = useUpdateFormContext();
  const serviceTypes = formData?.service_type || [];

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramsActiveTab = queryParams.get("tab");

  const currentUrl = window.location.href;
  const paymentPI = currentUrl.includes('from=paymentPI');
  const paymentHire = currentUrl.includes('from=paymentHire');
  const paymentRepair = currentUrl.includes('from=paymentRepair');
  const paymentStorage = currentUrl.includes('from=paymentStorage');
  const paymentRecovery = currentUrl.includes('from=paymentRecovery');
  const paymentInspection = currentUrl.includes('from=paymentInspection');
  const paymentAccessory = currentUrl.includes('from=paymentAccessory');

  useEffect(() => {
    if (serviceTypes.length > 0) {
      if (paymentPI) {
        setActiveTab("personal injury");
      } else if (paymentHire || paymentAccessory) {
        setActiveTab("hire");
      } else if (paymentRepair) {
        setActiveTab("repair");
      } else if (paymentStorage) {
        setActiveTab("storage");
      } else if (paymentRecovery) {
        setActiveTab("recovery");
      } else if (paymentInspection) {
        setActiveTab("inspection");
      } else if (paramsActiveTab) {
        setActiveTab(paramsActiveTab.toLowerCase());
      } else {
        setActiveTab(serviceTypes[0].toLowerCase());
      }
    }
  }, [caseId]);

  return (
    <div>
      <ul className="update-form-tabs">
        {serviceTypes.map((service) => (
          <li key={service} className={`${activeTab === service.toLowerCase() ? "active" : ""}`} onClick={() => setActiveTab(service.toLowerCase())}>
            {service}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UpdateFormTabs;
