import React from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { Col, Label, Row } from "reactstrap";
import instance from "../../../axiosInstance";
import { useUpdateFormContext } from "../../../context/administration/updateForm";
import useError from "../../../hooks/useError";
import ContactLink from "../../UI/links/ContactLink";

export const loadFileHandlerOptions = async (search, loadedOptions, { page }) => {
  try {
    const res = await instance.get("/lookups/fileHandlers", {
      params: { search, page, limit: 10 },
    });

    const { data } = res.data;
    const options = data.data.map((role) => ({
      value: role.user_id,
      label: role.full_name,
    }));

    return {
      options,
      hasMore: page < data.total_pages,
      additional: {
        page: page + 1,
      },
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

const UpdateFormHeader = () => {
  const { formData, fileHandler, hireFileHandler, setFileHandler, setHireFileHandler } = useUpdateFormContext();

  const { errorHandler } = useError();

  const updateHandler = async (combo, id) => {
    try {
      await instance.put(`/case/updateHandler/${formData.case_id}`, {
        file_handler: combo === "fileHandler" && id ? id : fileHandler?.value,
        hire_file_handler: combo === "hireFileHandler" && id ? id : hireFileHandler?.value,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <div className="border-bottom pb-4 mb-4">
      <Row>
        <Col md={6}>
          <p className="m-0">{`NASL-${formData.case_id}`}</p>
          <p className="m-0">Case Type: {formData.case_type}</p>
          <p className="m-0" style={{ textTransform: "capitalize" }}>
            Client: {`${formData.title} ${formData.first_name} ${formData.middle_name} ${formData.last_name}`}
          </p>
          <p className="m-0">Contact number: {<ContactLink>{formData.mobile_no}</ContactLink>}</p>
          <p className="m-0">
            Vehicle Registration No: <span style={{ background: "#FCD12A", padding: "0 10px" }}> {formData.vehicle_registration_no !== null ? formData.vehicle_registration_no : "NA"} </span>
          </p>
        </Col>
        <Col md={6}>
          <p className="m-0">Entered by: {formData.created_by !== null ? formData.created_by : "NA"}</p>
          <Row className="mt-2">
            <Col md={3} sm={4} xs={6}>
              <Label for="fileHandler">File Handler:</Label>
            </Col>
            <Col md={5} sm={4} xs={6}>
              <AsyncPaginate
                className="basic-single"
                classNamePrefix="select"
                isSearchable={true}
                name="fileHandler"
                id="fileHandler"
                loadOptions={loadFileHandlerOptions}
                value={fileHandler}
                additional={{
                  page: 1,
                }}
                debounceTimeout={300}
                onChange={(selected) => {
                  setFileHandler(selected);
                  updateHandler("fileHandler", selected.value);
                }}
              />
            </Col>
          </Row>
          <Row className="mt-1">
            <Col md={3} sm={4} xs={6}>
              <Label for="hireFileHandler">Hire File Handler:</Label>
            </Col>
            <Col md={5} sm={4} xs={6}>
              <AsyncPaginate
                className="basic-single"
                classNamePrefix="select"
                isSearchable={true}
                name="hireFileHandler"
                value={hireFileHandler}
                id="hireFileHandler"
                loadOptions={loadFileHandlerOptions}
                additional={{
                  page: 1,
                }}
                debounceTimeout={300}
                onChange={(selected) => {
                  setHireFileHandler(selected);
                  updateHandler("hireFileHandler", selected.value);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default UpdateFormHeader;
